import React from 'react';
import { css } from '@emotion/react';
import YouTube from "react-youtube"
import { TitleHeader, wrapper } from '../defaults/styles/elements';
import { color } from '../defaults/styles/theme';
import mq from '../defaults/styles/mediaquerys';
import Link from '../link';


const Testimonial = ({className, name, profession, company, placeholder, id}) => {
  return (
    <Link
      to={"/people/" + id}
      css={css`
        background: white;
        text-decoration: none;
        color: ${color.main_dark};
        border: 1px solid ${color.accent_light};
        border-radius: 5px;
        padding: ${placeholder ? "" : "0.5em 1em"};
        display: inline-flex;
        flex-direction: column;
        margin: 0.25em 0;
        visibility: ${placeholder ? "hidden" : "visible"};
        min-width: 0;
        flex: 0 0 100%;
        :first-of-type {
          margin-left: 0;
        }
        :last-of-type {
          margin-right: 0;
        }
        ${mq[0]} {
          flex: 0 0 calc((100% - .5em) / 2);
        }
        ${mq[1]} {
          flex: 0 0 calc((100% - 2em) / 3);
        }
        p {
          margin: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      `}
    >
      <p
        css={css`
          font-weight: 700;
          font-size: 0.75rem;
        `}
      >
        {name}
      </p>
      <p
        css={css`
          font-size: 0.65em;
        `}
      >
        {profession}
      </p>
      <p
        css={css`
          font-size: 0.65em;
        `}
      >
        {company}
      </p>
    </Link>
  )
}






const ProfileYoutube = ({className, videoId, title, icon, speakers, company, inline, ...props}) => {
  const opts = {
    width: "100%",
    height: "100%",
    playerVars: {
      color: "white",
      showinfo: 0,
    },
  }

  return (
    <section
      className={className}
      css={[
        css`
          background: ${color.main_light};
          margin-bottom: 5em;
          .youtube-container {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 0;
            height: 0;
            overflow: hidden;
          }
        `,
      ]}
    >
      <div css={!inline ? wrapper : ""}>
        <TitleHeader
          title={title}
          icon={icon}
          subtitle="<p>We asked our partners why they support the STARTUP AUTOBAHN powered by Plug and Play project and what they value the most about this program.</p><p>Here is what they said.</p>"
          inline
          transparent
          css={[css`
            margin-bottom: 1em;
            ${inline ? "h2 { font-size: 1em };" : ""}
            padding-top: 0;
          `]}
        />
        <div
          css={css`
            width: 100%;
            max-width: 40rem;
          `}
        >
          <YouTube
            videoId={videoId}
            containerClassName="youtube-container"
            opts={opts}
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            `}
          />
        </div>

        {speakers && speakers.length > 0 ? (
          <div
            css={css`
              padding-top: 1.5em;
            `}
          >
            <p
              css={css`
                margin: 0;
                margin-bottom: 0.5em;
                font-size: 0.75em;
              `}
            >
              This testimonial is supported by:
            </p>

            <div
              css={css`
                display: flex;
                width: 100%;
                max-width: 50rem;
                flex-wrap: wrap;
                justify-content: space-between;
              `}
            >
              {speakers.map(({ data, id }, index) => (
                <Testimonial
                  key={index}
                  name={data.name}
                  profession={data.profession}
                  company={company}
                  id={id}
                />
              ))}
              <Testimonial placeholder />
              <Testimonial placeholder />
            </div>
          </div>
        ) : (
          ""
        )}

      </div>
    </section>
  )
}

export default ProfileYoutube;